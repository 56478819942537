import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./translations/en.json";
import sv from "./translations/sv.json";
import no from "./translations/no.json";
import da from "./translations/da.json";
import de from "./translations/de.json";

i18next.use(initReactI18next).init({
  resources: {
    sv,
    en,
    no,
    da,
    de
  },
  fallbackLng: "sv",
  debug: true,
  lng: "sv",
});

export default i18next;
